import { Button } from "primereact/button";
import { Component, createRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../../util/Constante";
import Util from '../../../util/Util';
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService"
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import HeaderAdministrador from "../components/headerAdministrador";


export default class FinanceiroContasPagarPg extends Component {
    toast = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base)


    state = {

        pessoaUsuario: new Pessoa(),
        listaMovimentoFinanceiro: [],
        listaContaFinanceira: [],
        listaFormaPagamento: [],
        listaContaBancaria: [],
        listaLoteFinanceiro: [],
        selecionados: [],
        quantidade: 0,
        valor: 0,

        vModalNotaFiscal: false,

        //FILTRO
        filtro: {
            status: null,
            listaStatus: [{ nome: 'Pago', valor: 1 }, { nome: 'Não pago', valor: 0 }],
            seqContaBancaria: null,
            formaPagamento: null,
            seqContaFinanceira: '',
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataProgramada_inicial: '',
            dataProgramada_final: '',
            dataBaixa_inicial: '',
            dataBaixa_final: '',
            dataCredito_inicial: '',
            dataCredito_final: '',
            nome: ''
        }

    }

    componentDidMount() {
        this.iniciar()

    }

    async iniciar() {
        this.pessoaUsuario = this.state.pessoaUsuario
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        console.log("PESSOA USUARIO", this.pessoaUsuario);

        if (!this?.pessoaUsuario?._seqPessoa) {
            window.history.back();
            return
        } else if (this.pessoaUsuario?.seqPessoaPerfil !== this.constante.seqPerfilAdministrador) {
            window.history.back();
            return
        }

        this.retornoListaContaFinanceira = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaFinanceira);
        this.retornoListaFormaPagamento = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaFormaPagamento);
        this.retornoListaContaBancaria = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaBancaria);


        this.listaContaFinanceira = this.retornoListaContaFinanceira.objeto;
        console.log("CONTA FINANCEIRA", this.listaContaFinanceira);

        this.listaFormaPagamento = this.retornoListaFormaPagamento.objeto;
        console.log("CONTA Forma PAGAMENTO", this.listaFormaPagamento);

        this.listaContaBancaria = this.retornoListaContaBancaria.objeto;
        console.log("CONTA BANCARIA", this.listaContaBancaria);

        this.listarFinanceiro();

        this.setState({
            listaContaFinanceira: this.listaContaFinanceira,
            listaFormaPagamento: this.listaFormaPagamento,
            listaContaBancaria: this.listaContaBancaria,
            pessoaUsuario: this.pessoaUsuario,
        });

    }

    selecionar(item) {
        this.selecionados = item
        console.log(this.selecionados)

        this.setState({ selecionados: this.selecionados })

        return
        this.listaLoteFinanceiro = this.state.listaLoteFinanceiro
        this.ProfissionalSelecionado = item
        this.listaLoteFinanceiro.push(this.ProfissionalSelecionado)

        this.setState({ listaLoteFinanceiro: this.listaLoteFinanceiro })

        console.log("lista lote financeiro", this.listaLoteFinanceiro);
    }

    async listarFinanceiro() {

        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "movimento_financeiro.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),

            this.util.criarObjetoPesquisa(
                "movimento_financeiro.seq_conta_financeira",
                this.constante.seqContaFinanceiroPagamentoFornecedor,
                "AND",
                "TEXTO"
            )
        ]

        if (this.state.filtro.nome) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "pessoa.nome",
                    this.state.filtro.nome,
                    "AND",
                    "TEXTO"
                )
            )
        }

        if (this.state.filtro.formaPagamento?._seq) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_forma_pagamento",
                    this.state.filtro.formaPagamento?._seq,
                    "AND",
                    "TEXTO"
                )
            )
        }


        if (this.state.filtro.status?.valor) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.qtde_baixa',
                    this.state.filtro.status?.valor,
                    'AND',
                    'NUMERO'
                )
            )
        }


        if (this.state.filtro.dataCadastro_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_cadastro',
                    this.state.filtro.dataCadastro_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }

        if (this.state.filtro.dataCadastro_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_cadastro',
                    this.state.filtro.dataCadastro_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }



        if (this.state.filtro.dataProgramada_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_programado',
                    this.state.filtro.dataProgramada_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }


        if (this.state.filtro.dataProgramada_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_programado',
                    this.state.filtro.dataProgramada_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }



        if (this.state.filtro.dataCredito_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.state.filtro.dataCredito_inicial,
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.filtro.dataCredito_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.state.filtro.dataCredito_final,
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        console.log("pesquisaa", this.listaPesquisa);

        this.retornoListaFinanceiro = await this.financeiroService.listarComFiltro(this.listaPesquisa);
        this.listaMovimentoFinanceiro = this.retornoListaFinanceiro.objeto
        console.log("LISTA DO FINANCEIRO", this.listaMovimentoFinanceiro);
        console.log("NUMERO DE ITENS NO ARRAY", this.retornoListaFinanceiro.objeto.length)

        this.valor = 0

        if (this.listaMovimentoFinanceiro.length > 0) {
            for (let i = 0; i < this.listaMovimentoFinanceiro.length; i++) {
                this.valor = this.valor + this.listaMovimentoFinanceiro[i].valorProgramado
            }
        }

        this.setState({
            listaMovimentoFinanceiro: this.listaMovimentoFinanceiro,
            quantidade: this.listaMovimentoFinanceiro.length,
            valor: this.valor
        });
    }

    exportExcel() {
        this.listaMovimentoFinanceiro = this.state.listaMovimentoFinanceiro;
        this.lista = []

        this.listaMovimentoFinanceiro.forEach(financeiro => {
            let objeto = {};
            objeto.ordemServico = financeiro._codigo;
            objeto.profissional = financeiro._pesNome;
            objeto.valorProgramado = financeiro.valorProgramado;
            objeto.operacao = financeiro._cfOperacao;
            objeto.formaPagamento = financeiro._fpNome;
            objeto.qtdeBaixa = financeiro.qtdeBaixa;
            objeto.dataBaixa = this.util.formatarData(financeiro._dataBaixa);
            objeto.dataProgramada = this.util.formatarData(financeiro.dataProgramado);
            objeto.dataCredito = this.util.formatarData(financeiro._dataCredito);
            objeto.dataCadastro = this.util.formatarData(financeiro.dataCadastro);

            this.lista.push(objeto)
        })

        this.listaMovimentoFinanceiro = this.lista;
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.listaMovimentoFinanceiro);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array"
            });
            this.saveAsExcelFile(excelBuffer, `financeiroContasApagar`);
        });
    }

    saveAsExcelFile(buffer, fileName) {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(
                    data,
                    fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
                );
            }
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <Dialog header="Alerta" visible={this.state.vModalNotaFiscal} style={{ width: '100%', maxWidth: '60%' }} onHide={() => this.setState({ vModalNotaFiscal: false, })}>
                <h2 style={{ fontSize: 20 }}>Deseja solicitar uma nota fiscal para os profissionais abaixo?</h2>
                <br />
                {this.state.selecionados.map(pProfissional =>
                    <>
                        <p> - {pProfissional._pesNome}</p><br />
                    </>
                )}
                <br />

                <div style={{ display: "flex", alignItems: 'center', justifyContent: "flex-end", gap: 20 }}>
                    <Button type="button" label="Sim" onClick={() => {
                        this.toast.current.show([
                            { severity: 'success', summary: 'Nota fiscal solicitada com sucesso!' },
                        ]);
                        this.setState({
                            vModalNotaFiscal: false,
                            selecionados: [],
                        });
                    }} />
                    <Button type="button" label="Não" onClick={() => this.setState({ vModalNotaFiscal: false })} />
                </div>
            </Dialog>

            <HeaderAdministrador />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22, }}>Contas a pagar </h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Contas a pagar</h2>
                        </div>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>

                        <div>
                            <label>Cliente </label><br />
                            <InputText
                                onChange={pTexto => this.setState({ filtro: { ...this.state.filtro, nome: pTexto.target.value } })}
                                value={this.state.filtro.nome || ''}
                                placeholder="Cliente"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>

                        {/* <div>
                            <label>Forma de Pagamento</label><br />
                            <Dropdown
                                onChange={e => this.setState({ filtro: { ...this.state.filtro, formaPagamento: e.value } })}
                                value={this.state.filtro.formaPagamento}
                                options={this.state.listaFormaPagamento}
                                optionLabel={'_nome'}
                                style={{ width: '100%', height: 40 }}
                                placeholder="Todos" />
                        </div> */}
                        <div>
                            <label>Status</label><br />
                            <Dropdown
                                onChange={e => this.setState({ filtro: { ...this.state.filtro, status: e.value } })}
                                value={this.state.filtro.status}
                                options={this.state.filtro.listaStatus}
                                optionLabel={'nome'}
                                style={{ width: '100%', height: 40 }}
                                placeholder="Todos" />
                        </div>
                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_inicial || ''}
                                style={{ width: '100%', height: 40 }}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_final || ''}
                                style={{ width: '100%', height: 40 }}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data programada inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataProgramada_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataProgramada_inicial || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data programada final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataProgramada_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataProgramada_final || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data credito inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCredito_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCredito_inicial || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data credito final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCredito_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCredito_final || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 40 }}>
                        <div style={{
                            display: 'flex',
                            gap: 40,
                            paddingLeft: 10,
                            width: '90%',
                        }}>
                            <div>

                                <label
                                    style={{ fontSize: '20px', fontWeight: '700' }}
                                >Quantidade </label><br />
                                <p
                                    style={{
                                        fontSize: '20px',
                                        color: 'black',
                                    }}
                                >{this.state.quantidade}</p>
                            </div>

                            <div >

                                <label
                                    style={{ fontSize: '20px', fontWeight: '700' }}
                                >Valor á receber</label><br />
                                <p
                                    style={{
                                        fontSize: '20px',
                                        color: 'black',
                                    }}
                                >{this.util.formatarValor(this.state.valor)}</p>
                            </div>

                        </div>


                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%', height: 40, marginTop: 15 }}>
                            <Button label="Buscar" className="p-button-raised" onClick={() => this.listarFinanceiro()} />
                        </div>
                    </div>
                </div>

                <DataTable
                    style={{ marginTop: 20 }}
                    showGridlines
                    header={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button type="button" icon="pi pi-file-excel" onClick={() => this.exportExcel()} className="p-button-success mr-2" data-pr-tooltip="XLS" />

                            <div>
                                <Button type="button" label="Solicitar Nota fiscal" onClick={() => this.setState({ vModalNotaFiscal: true })} />
                            </div>
                        </div>
                    }
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    responsiveLayout='scroll'
                    removableSort
                    value={this.state.listaMovimentoFinanceiro}
                    selection={this.state.selecionados}
                    onSelectionChange={item => this.selecionar(item.value)}>

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />

                    <Column
                        header="Ordem de serviço"
                        field="_codigo"
                        sortable />

                    <Column
                        header="Profissional"
                        field="_pesNome"
                        sortable />

                    <Column
                        field="valorProgramado"
                        header="Valor programado"
                        sortable
                        body={item => <p>{this.util.formatarValor(item.valorProgramado)}</p>} />

                    <Column
                        field="_cfOperacao"
                        header="Operação"
                        sortable />

                    <Column
                        field="_fpNome"
                        header="Forma de pagamento" sortable />

                    <Column
                        field="qtdeBaixa"
                        header="Status"
                        sortable body={item => <p>{
                            item.qtdeBaixa === 0 ? 'Não pago' : 'Pago'
                        }</p>} />

                    <Column
                        header="Nota fiscal"
                        sortable
                        align="center"
                        body={() => <>
                            <label
                                htmlFor="file-upload"
                                style={{ padding: 10, backgroundColor: 'var(--primary-color)', borderRadius: 5, color: 'white', cursor: 'pointer' }}>
                                <i className="pi pi-upload"></i>
                            </label>
                            <input type="file" id="file-upload" style={{ display: 'none' }} />
                        </>} />

                    <Column
                        field="qtdeBaixa"
                        header="Status"
                        sortable body={item => <p>{
                            item.qtdeBaixa === 0 ? 'Não pago' : 'Pago'
                        }</p>} />

                    <Column
                        field="_dataBaixa)"
                        header="Data baixa"
                        sortable body={item => <p>{this.util.formatarData(item._dataBaixa)}</p>
                        } />

                    <Column
                        header="Data prevista de pagamento"
                        field="dataProgramado"
                        sortable body={item => <p>{this.util.formatarData(item.dataProgramado)}</p>
                        } />

                    <Column
                        field="_dataCredito"
                        header="Data crédito"
                        sortable body={item => <p>{this.util.formatarData(item._dataCredito)}</p>
                        } />

                    <Column
                        field="dataCadastro"
                        header="Data cadastro"
                        sortable body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                        } />
                </DataTable>
            </main>
        </div>
    }
}