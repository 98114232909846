import { Button } from "primereact/button";
import { Component } from "react";
import Header from "./components/header";
import { Menu } from 'primereact/menu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Navigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import DepartamentoService from 'lirida-back-service/Servicos/Departamento/DepartamentoService';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import TipoDocumentoProcesso from 'lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcesso';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import TabelaPrecoService from 'lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService'



export default class DocumentoPg extends Component {

    constante = new Constante();
    util = new Util();
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base)

    state = {
        listaDepartamento: [],
        listaDocumento: [],
        listaVwContadorDocumento: [],
        listaRelacionamentoDocumentoItem: [],
        listaItem: [],
        localServico: '',
        tipoDocumentoProcesso: new TipoDocumentoProcesso(),
        pessoaUsuario: new Pessoa(),
        documentoItem: new DocumentoItem(),
        liberarAcesso: {
            pedidoDetalhe: false,
        },

        filtroPesquisa: {
            statusSelecionado: null,
            itemNome: null,
            nomeCliente: '',
            nomeVendedor: '',
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataProgramada_inicial: '',
            dataProgramada_final: ''

        }
    }


    componentDidMount() {
        this.iniciar();

    }


    async iniciar() {
        // this.util.traduzirCalendar();
        this.pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        // if (!this?.pessoaUsuario?._seqPessoa) {
        //     window.history.back()
        //     return
        // }

        this.retornoVwContadorDocumento = await this.documentoService.listarContadorDocumento(this.constante.seqUsuario, this.constante.seqTipoDocumentoPedido);
        console.log('Lista de processo', this.retornoVwContadorDocumento.objeto);


        this.retornoDepartamento = await this.departamentoService.listar(this.constante.seqUsuario);

        this.setState({
            listaDepartamento: this.retornoDepartamento.objeto,
            pessoaUsuario: this.pessoaUsuario,
            listaVwContadorDocumento: this.retornoVwContadorDocumento.objeto,

        })

        this.listar(this.retornoVwContadorDocumento.objeto[0], this.pessoaUsuario);
    }


    async listar(pTipoDocumentoProcesso, pPessoa) {
        if (pTipoDocumentoProcesso) {
            this.tipoDocumentoProcesso = pTipoDocumentoProcesso
        } else {
            this.tipoDocumentoProcesso = this.state.listaVwContadorDocumento;
        }

        if (!pPessoa) {
            this.pessoaUsuario = this.state.pessoaUsuario

        } else {
            this.pessoaUsuario = pPessoa
        }

        this.pesquisa = [
            this.util.criarObjetoPesquisa(
                "documento.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),

            this.util.criarObjetoPesquisa(
                "documento.seq_usuario_vendedor",
                this.pessoaUsuario._seqPessoa,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "documento.seq_tipo_documento",
                this.constante.seqTipoDocumentoPedido,
                "AND",
                "TEXTO"
            ),

            this.util.criarObjetoPesquisa(
                "documento.seq_tipo_documento_processo",
                this.tipoDocumentoProcesso._seqTipoDocumentoProcesso,
                "AND",
                "TEXTO"
            ),

        ]

        if (this.state.filtroPesquisa.nomeCliente) {
            this.pesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.tags1',
                    this.state.filtroPesquisa.nomeCliente,
                    'AND',
                    'TEXTO'
                )
            )
        }
        if (this.state.filtroPesquisa.nomeVendedor) {
            this.pesquisa.push(
                this.util.criarObjetoPesquisa(
                    'pessoa.nome',
                    this.state.filtroPesquisa.nomeVendedor,
                    'AND',
                    'TEXTO'
                )
            )
        }

        if (this.state.filtroPesquisa?.dataCadastro_inicial) {
            this.pesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_cadastro',
                    (this.state.filtroPesquisa.dataCadastro_inicial).toLocaleDateString("PT-BR"),
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }
        if (this.state.filtroPesquisa?.dataCadastro_final) {
            this.pesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_cadastro',
                    (this.state.filtroPesquisa.dataCadastro_inicial).toLocaleDateString("PT-BR"),
                    'AND',
                    'DATA_FINAL'
                )
            )
        }

        console.log("Pesquisa", this.pesquisa);

        this.retornoListaDocumento = await this.documentoService.listarComFiltro(this.pesquisa);
        this.listaDocumento = this.retornoListaDocumento.objeto;

        this.setState({
            listaDocumento: this.listaDocumento
        })
    }
    selecionar(pDocumento) {
        this.setState({ documento: pDocumento });
        this.navegar('pedidoDetalhe');
        console.log(pDocumento);

    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.pedidoDetalhe && <Navigate to={"/sistema/lojista/pedido_detalhe?id=" + this.state.documento._seqDocumento} />}

            {/* TELA */}
            <Header />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22 }}>Pedidos</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Pedidos</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr ', gap: 20 }}>


                        <div>
                            <label>Cliente</label><br />
                            <InputText
                                placeholder="Nome"
                                onChange={pTexto => this.setState({ filtroPesquisa: { ...this.state.filtroPesquisa, nomeCliente: pTexto.target.value } })}
                                value={this.state.filtroPesquisa.nomeCliente || ''}
                                style={{ width: '100%', }}
                            />
                        </div>

                        <div>
                            <label>Vendedor</label><br />
                            <InputText
                                placeholder="Nome"
                                onChange={pTexto => this.setState({ filtroPesquisa: { ...this.state.filtroPesquisa, nomeVendedor: pTexto.target.value } })}
                                value={this.state.filtroPesquisa.nomeVendedor || ''}
                                style={{ width: '100%', }}
                            />
                        </div>

                        <div>
                            <label>Processo</label><br />
                            <Dropdown
                                onChange={pItem => this.setState({ filtroPesquisa: { ...this.state.filtroPesquisa, statusSelecionado: pItem.target.value } })}
                                value={this.state.filtroPesquisa.statusSelecionado}
                                options={this.state.listaVwContadorDocumento}
                                optionLabel={'_tipoDocumentoProcessoNome'}
                                style={{ width: '100%', }}
                                placeholder='Selecione'
                            />
                        </div>

                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataCadastro_inicial: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtroPesquisa.dataCadastro_inicial || ''}
                                showButtonBar
                                style={{ width: '100%', }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>

                            <label>Data cadastro final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataCadastro_final: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtroPesquisa.dataCadastro_final || ''}
                                showButtonBar
                                style={{ width: '100%', }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>



                    </div>

                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                    </div>
                </div>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr', marginTop: 20, gap: 20, }}>

                    <DataTable
                        selectionMode="single"
                        showGridlines
                        removableSort
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        value={this.state.listaDocumento}
                        onSelectionChange={pDocumento => this.selecionar(pDocumento.value)}>
                        <Column
                            header="Status"
                            field="_tipoDocumentoProcessoNome"
                            sortable />
                        <Column
                            header="Cliente"
                            field="tags1"
                            sortable />
                        <Column
                            header="Vendedor"
                            field="_pessoaNome"
                            sortable />
                        <Column
                            header="Valor total"
                            field="valorTotal"
                            sortable
                            body={item => <p>{this.util.formatarValor(item.valorTotal)}</p>} />
                        <Column
                            field="dataCadastro"
                            header="Data cadastro"
                            sortable
                            body={item => <p>{this.util.formatarDataComTimestamp(item.dataCadastro)}</p>} />
                    </DataTable>
                </div>
            </main >

        </div >
    }
}