import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import ItemCategoria from 'lirida-back-service/Servicos/ItemCategoria/ItemCategoria';
import ItemCategoriaRelacionamento from 'lirida-back-service/Servicos/ItemCategoriaRelacionamento/ItemCategoriaRelacionamento';
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService"
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa"
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

export default class ItemCategoriaDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    toast = createRef();

    state = {
        urlBase: "",
        base64: "",
        itemCategoria: new ItemCategoria(),
        ItemCategoriaRelacionamento: new ItemCategoriaRelacionamento(),
        pessoaUsuario: new Pessoa(),
        carregando: false,
        inputError: {
            codigo: false,
            nome: false,
            descricao: false,
            situacao: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const itemCategoria = this.props.itemCategoria;
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO")

        this.setState({
            itemCategoria: itemCategoria,
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario
        });
    }

    async salvar() {
        let itemCategoria = this.state.itemCategoria;
        const inputError = this.state.inputError;
        let inputOK = true;
        const pessoaUsuario = this.state.pessoaUsuario

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!itemCategoria.codigo) {
            inputError.codigo = true;
        }

        if (!itemCategoria.nome) {
            inputError.nome = true;
        }

        if (!itemCategoria.descricao) {
            inputError.descricao = true;
        }

        if (!itemCategoria.situacao) {
            inputError.situacao = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ carregando: true });

        itemCategoria.seqUsuario = this.constante.seqUsuario;
        itemCategoria.seqPessoa = pessoaUsuario._seqPessoa;

        if (this.state.base64) {
            itemCategoria.imagem = this.state.base64;
        }

        const retornoItem = await this.itemCategoriaService.salvar(itemCategoria);
        itemCategoria = retornoItem.objeto;
        console.log("categoria salva", itemCategoria);
        
        this.toast.current.show([
            { severity: 'success', summary: 'Categoria cadastrada com sucesso!' },
        ]);

        this.setState({
            itemCategoria: itemCategoria,
            carregando: false,
            base64: "",
        });

        this.props.listar();
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                        <Button
                            label={<i className="pi pi-arrow-left" />}
                            onClick={() =>
                                this.props.setState({
                                    itemCategoria: new ItemCategoria(),
                                    tela: "1"
                                })} />
                        <h1 style={{ fontSize: 22.5 }}>Detalhe da categoria</h1>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button
                            loading={this.state.carregando}
                            label="Salvar"
                            onClick={() => this.salvar()} />
                    </div>
                </div>

                <div style={{ padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5, marginTop: 20, }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <div style={{ width: 200, height: 200, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {this.state.itemCategoria.imagem ?
                                <img
                                    src={this.state.urlBase + this.state.itemCategoria.imagem}
                                    style={{ width: 200, height: 200, objectFit: 'contain' }} />
                                :
                                <p>sem imagem</p>
                            }
                        </div>
                        <InputText
                            type="file"
                            onChange={async pEvento => {
                                console.log(pEvento.target.files[0]);
                                this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        imagem: pEvento.target.files[0]
                                    },
                                    base64: await this.util.pegarBase64(pEvento.target.files[0]),
                                });
                            }} />
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                        <div>
                            <label>Código</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.codigo && 'red' }}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        codigo: pEvento.target.value
                                    }
                                })}
                                value={this.state.itemCategoria.codigo || ""} />
                            {this.state.inputError.codigo && <small style={{ color: 'red' }}>Código inválido</small>}
                        </div>
                        <div>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.itemCategoria.nome || ""} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div>
                            <label>Descrição</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.descricao && 'red' }}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        descricao: pEvento.target.value
                                    }
                                })}
                                value={this.state.itemCategoria.descricao || ""} />
                            {this.state.inputError.descricao && <small style={{ color: 'red' }}>Descrição inválido</small>}
                        </div>
                        <div>
                            <label>Situação</label><br />
                            <Dropdown
                                placeholder="Selecione"
                                style={{ width: '100%', borderColor: this.state.inputError.situacao && 'red' }}
                                options={[
                                    { label: "ATIVO", value: "ATIVO" },
                                    { label: "INATIVO", value: "INATIVO" },
                                ]}
                                onChange={pEvento => this.setState({
                                    itemCategoria: {
                                        ...this.state.itemCategoria,
                                        situacao: pEvento.value
                                    }
                                })}
                                value={this.state.itemCategoria.situacao} />
                            {this.state.inputError.situacao && <small style={{ color: 'red' }}>Situação inválida</small>}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}