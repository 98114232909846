import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Component, } from "react";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Toast } from "primereact/toast";

export default class VenderdorDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoa: new Pessoa(),
        urlBase: "",
       
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        let pessoaRelacionamento = this.props.pessoaRelacionamento;
        const urlBase = await this.util.buscarUrlBaseArquivo();

        console.log(pessoaRelacionamento);

        const retornoListaPessoa = await this.pessoaService.buscarPorSeqPessoa(pessoaRelacionamento.seqPessoaRelacionada, this.constante.seqUsuario);
        const pessoa = retornoListaPessoa.objeto;
        console.log(pessoa);

        this.setState({
            pessoa: pessoa,
            urlBase: urlBase
           
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                        <Button
                            label={<i className="pi pi-arrow-left" />}
                            onClick={() => this.props.setState({
                                pessoa: new Pessoa,
                                tela: "1"
                            })} />
                        <h1 style={{ fontSize: 22.5 }}>Detalhe do Vendedor</h1>
                    </div>
                </div>

                <div style={{ padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ width: 200, height: 200, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                        {this.state.pessoa.foto ?
                            <img
                                src={this.state.urlBase + this.state.pessoa.foto}
                                style={{ width: 200, height: 200, objectFit: 'contain' }} />
                            :
                            <p>sem imagem</p>
                        }
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", marginTop: 20, gap: 20 }}>
                        <div>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: '100%' }}
                                value={this.state.pessoa.nome || ""} />
                        </div>
                        
                        <div>
                            <label>CNPJ</label><br />
                            <InputText
                                style={{ width: '100%' }}
                                value={this.state.pessoa.documento || ""} />
                        </div>
                        <div>
                            <label>Email</label><br />
                            <InputText
                                style={{ width: '100%' }}
                                value={this.state.pessoa.emailPlataformaLirida || ""} />
                        </div>
                        <div>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: '100%' }}
                                value={this.state.pessoa.telefonePlataformaLirida || ""} />
                        </div>

                        <div>
                            <label>Situação</label><br />
                            <InputText
                                style={{ width: '100%' }}
                                value={this.state.pessoa.situacao || ""} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}