import React, { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// import { Navigate } from "react-router-dom";
import { Messages } from "primereact/messages";

import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

import Logo from "../../assets/imagens/logo.png"

import Constante from "../../util/Constante";
import Util from "../../util/Util";

export default class CadastroPg extends Component {

    util = new Util();
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    mensagensCadastro = createRef();


    state = {
        pessoaUsuario: new Pessoa(),
        carregando: false,
        conteudo: '',

        inputsCadastroError: {
            nome: false,
            email: false,
            documento: false,
            telefone: false,
        },
    }



    validarInputs() {
        const inputsCadastroError = this.state.inputsCadastroError;
        const listaChaveinputsCadastroError = Object.keys(inputsCadastroError);

        for (let i = 0; i < listaChaveinputsCadastroError.length; i++) {
            inputsCadastroError[listaChaveinputsCadastroError[i]] = false;
        }

        if (!this.state.pessoaUsuario.nome) {
            inputsCadastroError.nome = true;
        }

        if (!this.state.pessoaUsuario.emailPlataformaLirida) {
            inputsCadastroError.email = true;
        }

        if (!this.state.pessoaUsuario.documento || this.state.pessoaUsuario.documento.length < 18) {
            inputsCadastroError.documento = true;
        }

        if (!this.state.pessoaUsuario.telefonePlataformaLirida) {
            inputsCadastroError.telefone = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveinputsCadastroError.length; i++) {
            if (inputsCadastroError[listaChaveinputsCadastroError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputsCadastroError: inputsCadastroError });

        return inputOK;
    }


    async cadastrar(e) {
        this.setState({ carregando: true })

        this.pessoaUsuario = this.state.pessoaUsuario
        this.pessoaUsuario.seqUsuario = this.constante.seqUsuario;
        this.pessoaUsuario.tipoDocumento = "CNPJ";
        this.pessoaUsuario.seqPessoaPerfil = this.constante.seqPerfilLojista;

        console.log("Pessoa Usuario", this.pessoaUsuario);


        const listaPesquisa = [];

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        )

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.email_plataforma_lirida",
                this.pessoaUsuario.emailPlataformaLirida,
                "AND",
                "TEXTO"
            )
        )
        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPerfilLojista,
                "AND",
                "TEXTO"
            )
        )

        this.retornoListaPesquisa = await this.pessoaService.listarComFiltro(listaPesquisa);
        this.pessoa = this.retornoListaPesquisa.objeto[0]


        if (this.pessoa) {

            this.mensagensCadastro.current.show([
                { severity: 'error', summary: 'email já esta em uso!', sticky: true },
            ]);
            this.setState({ carregando: false });
            return
        }

        this.retornoPessoaUsuario = await this.pessoaService.salvar(this.pessoaUsuario);
        this.pessoaUsuario = this.retornoPessoaUsuario.objeto;
        console.log("pessoaUsuario salva", this.pessoaUsuario);

        if (this.retornoPessoaUsuario === false) {

            this.mensagensCadastro.current.show([
                { severity: 'error', summary: this.retornoPessoaUsuario.msg, sticky: true },
            ]);

        }

        const apiEmail = {
            seqEmail: this.constante.seqEmailInformaçõesAcesso,
            email: this.pessoaUsuario.emailPlataformaLirida,
            parametro: {
                nome: this.pessoaUsuario.nome,
                email: this.pessoaUsuario.emailPlataformaLirida,
                senha: this.pessoaUsuario.senha
            }
        }

        this.util.enviarEmail(apiEmail);

        setTimeout(() => {
            this.mensagensCadastro.current.show([
                { severity: 'success', summary: 'Cadastro concluído com sucesso! Enviamos as credênciais de acesso para o seu email!', sticky: true },
            ]);
            this.setState({ carregando: false, pessoa: new Pessoa() });
            
        }, 2000);

       
        
    }





    render() {
        return <div>

            {/* TELA */}

            <main style={{ width: '90%', display: 'flex', alignItems: 'center', gap: 150, padding: 70 }}>
                <img src={Logo} alt="AwObras" style={{ width: 400, height: 400, borderRadius: 200, objectFit: 'contain' }} />


                <form onSubmit={e => {
                    e.preventDefault();
                    const retorno = this.validarInputs();
                    if (retorno) this.cadastrar()
                }}
                    style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Messages ref={this.mensagensCadastro} />

                    <h1>Cadastro</h1>

                    <div>
                        <label>Nome</label><br />
                        <InputText
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    nome: e.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.nome || ''}
                            style={{ width: '100%', borderColor: this.state.inputsCadastroError.nome && 'var(--red-600)' }} />
                        {this.state.inputsCadastroError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                    </div>

                    <div>
                        <label>Email</label><br />
                        <InputText
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    emailPlataformaLirida: e.target.value
                                }
                            })}
                            value={this.state.pessoaUsuario.emailPlataformaLirida || ''}
                            style={{ width: '100%', borderColor: this.state.inputsCadastroError.email && 'var(--red-600)' }} />
                        {this.state.inputsCadastroError.email && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                    </div>


                    <div>
                        <label>CNPJ</label><br />
                        <InputText
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    documento: this.util.formatarCNPJ(e.target.value)
                                }
                            })}
                            value={this.state.pessoaUsuario.documento || ''}
                            style={{ width: '100%', borderColor: this.state.inputsCadastroError.documento && 'var(--red-600)' }} />
                        {this.state.inputsCadastroError.documento && <small style={{ color: 'var(--red-600)' }}>cnpj inválido</small>}
                    </div>

                    <div>
                        <label>Telefone</label><br />
                        <InputText
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    telefonePlataformaLirida: this.util.formatarTelefone(e.target.value)
                                }
                            })}
                            value={this.state.pessoaUsuario.telefonePlataformaLirida || ''}
                            style={{ width: '100%', borderColor: this.state.inputsCadastroError.telefone && 'var(--red-600)' }} />
                        {this.state.inputsCadastroError.telefone && <small style={{ color: 'var(--red-600)' }}>Telefone inválido</small>}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button label="Login" type="button" className="p-button-link" style={{ padding: 0 }} onClick={() => window.location.href = "/login"} />
                        <Button label="Cadastrar" type="submit" loading={this.state.carregando} />
                    </div>
                </form>
            </main>
        </div>
    }
}