import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from 'primereact/tabview';
import { Component } from "react";
import { Navigate } from "react-router-dom";
import Header from "../components/header";
import { Dialog } from 'primereact/dialog';
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import Util from "../../../util/Util";
import Constante from "../../../util/Constante";
import HeaderAdministrador from "../components/headerAdministrador";

export default class PedidoDetalheAdm extends Component {
    util = new Util();
    constante = new Constante()
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        documento: new Documento(),
        liberarAcesso: {
            pedidos: false,
        },
        salvarCarregando: false,
        deletarCarregando: false,
        vModalDeletar: false,
        listaDocumentoItem: []
    }

    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        const parametro = this.util.buscarParametros();
        let pessoaUsuario = this.util.buscarLocalStorage("PESSOA_USUARIO")

        let documento = await this.buscarDocumento(parametro.id);
        console.log("Documento", documento);

        let documentoItem = await this.documentoService.listarDocumentoItem(documento)
        let listaDocumentoItem = documentoItem.objeto
        console.log("documento item", listaDocumentoItem);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            documento: documento,
            listaDocumentoItem: listaDocumentoItem
        })
    }

    async buscarDocumento(pSeqDocumento) {
        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'documento.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'documento.seq_documento',
                pSeqDocumento,
                'AND',
                'TEXTO',
            )
        ]

        this.retorno = await this.documentoService.listarComFiltro(this.listaPesquisa);
        return this.retorno.objeto[0]
    }



    salvar() {
        this.setState({ salvarCarregando: true });
        setTimeout(() => this.setState({ salvarCarregando: false, salvarCheck: true, }), 2000);
        setTimeout(() => this.setState({ salvarCheck: false, }), 4000);
    }

    deletar() {
        this.setState({ deletarCarregando: true });
        setTimeout(() => this.setState({ deletarCarregando: false, }), 2000);
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.pedidos && <Navigate to="/sistema/administrador/pedidos" />}

            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletar();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* TELA */}
            {/* <Header /> */}
            <HeaderAdministrador />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.navegar('pedidos')} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe pedido</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe pedido</h2>
                        </div>
                    </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <div>
                        <label>Vendedor</label><br />
                        <InputText value={this.state.documento?._pessoaNome} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Cliente</label><br />
                        <InputText value={this.state.documento?.tags1} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Email</label><br />
                        <InputText value={this.state.documento?.tags2} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Telefone</label><br />
                        <InputText value={this.state.documento?.tags5} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Data cadastro</label><br />
                        <InputText value={this.util.formatarDataComTimestamp(this.state.documento?.dataCadastro)} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Valor</label><br />
                        <InputText value={this.util.formatarValor(this.state.documento?.valorTotal)} disabled style={{ width: '100%' }} />
                    </div>

                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>


                    <TabView style={{ marginTop: 10 }}>

                        <TabPanel header="Pedido">
                            <DataTable
                                selectionMode="single"
                                showGridlines
                                removableSort
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaDocumentoItem}
                                nSelectionChange={e => this.selecionar(e.value)}>
                                <Column
                                    header="Item"
                                    field="_itemNome"
                                />
                                <Column
                                    field="quantidade"
                                    header="Quantidade"
                                />
                                <Column
                                    header="Valor Unitário"
                                    field="precoUnitario"
                                    body={item => this.util.formatarValor(item.precoUnitario)}
                                />
                                <Column
                                    header="Valor Total"
                                    field="valorTotal"
                                    body={item => this.util.formatarValor(item.valorTotal)}
                                />
                                <Column
                                    header="Data do Pedido"
                                    field="dataCadastro"
                                    body={item => this.util.formatarDataComTimestamp(item.dataCadastro)}

                                />
                            </DataTable>
                        </TabPanel>

                        <TabPanel header="Nota Fiscal">

                            <DataTable
                                selectionMode="single"
                                showGridlines
                                removableSort
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={''}
                                nSelectionChange={e => this.selecionar(e.value)}>
                                <Column
                                    header="Nota Fiscal"
                                    field=""
                                />
                            </DataTable>
                        </TabPanel>

                        <TabPanel header="Relacionamento">
                            <DataTable
                                selectionMode="single"
                                showGridlines
                                removableSort
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={''}
                                nSelectionChange={e => this.selecionar(e.value)}>
                                <Column field="_pessoaRelacionadaNome" header="Nome"></Column>
                                <Column field="_tipoRelacionamentoNome" header="Tipo relacionamento"></Column>
                                <Column field="dataCadastro" header="Data cadastro"></Column>
                            </DataTable>
                        </TabPanel>

                       
                    </TabView>
                </div>
            </main>
        </div>
    }
}