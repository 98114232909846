import { Component } from "react";
import HeaderAdministrador from "../components/headerAdministrador";

export default class DashboardAdministrador extends Component {
    render() {
        return <div>
            <HeaderAdministrador />

            <main style={{ height: "calc(100vh - 50px)", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 70, gap: 20 }}>
                <div style={{ width: '100%', height: '100%', display: 'flex', gap: 20 }}>
                    <div style={{ width: '100%', height: '100%', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: 5 }}></div>
                    <div style={{ width: '100%', height: '100%', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: 5 }}></div>
                </div>
                
                <div style={{ width: '100%', height: '100%', display: 'flex', gap: 20 }}>
                    <div style={{ width: '100%', height: '100%', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: 5 }}></div>
                    <div style={{ width: '100%', height: '100%', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: 5 }}></div>
                </div>
            </main>
        </div>
    }
}