import { Component } from "react";
import Header from "./components/header";
import ItemCategoria from 'lirida-back-service/Servicos/ItemCategoria/ItemCategoria';
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import ItemCategoriaService from "lirida-back-service/Servicos/ItemCategoria/ItemCategoriaService";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ItemCategoriaDetalhe from "./itemCategoriaDetalhe";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { InputText } from "primereact/inputtext";

export default class ItemCategoriaPg extends Component {
    constante = new Constante();
    util = new Util();
    itemCategoriaService = new ItemCategoriaService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        urlBase: "",
        pessoaUsuario: new Pessoa(),
        itemCategoria: new ItemCategoria(),
        listaItemCategoria: [],
        conteudo: "",
        tela: "1",
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        this.listar(pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
        })
    }

    async listar(pPessoa) {
        if (!pPessoa) {
            pPessoa = this.state.pessoaUsuario
        }
        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "item_categoria.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item_categoria.seq_pessoa";
        pesquisa.conteudo = pPessoa._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);


        pesquisa = new Pesquisa();
        pesquisa.campo = "item_categoria.nome";
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        console.log(listaPesquisa);

        const retornoListaCategoria = await this.itemCategoriaService.listarComFiltro(listaPesquisa);
        const listaItemCategoria = retornoListaCategoria.objeto;
        console.log(listaItemCategoria);

        this.setState({ listaItemCategoria: listaItemCategoria })
    }

    render() {
        return <div>
            <Header />

            {this.state.tela === "2" ?
                <ItemCategoriaDetalhe
                    listar={() => this.listar()}
                    setState={pState => this.setState(pState)}
                    itemCategoria={this.state.itemCategoria} />
                :
                <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                        <h1 style={{ fontSize: 22.5 }}>Cadastro de categoria</h1>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                            <InputText
                                placeholder="Pesquisar"
                                onChange={pEvento => this.setState({ conteudo: pEvento.target.value })}
                                value={this.state.conteudo} />
                            <Button
                                label="Buscar"
                                onClick={() => this.listar()}
                                className="p-button-raised" />
                            <Button
                                label="Novo"
                                onClick={() => this.setState({
                                    tela: "2",
                                })}
                                className="p-button-raised" />
                        </div>
                    </div>

                    <DataTable
                        style={{ marginTop: 20 }}
                        selectionMode="single"
                        showGridlines
                        removableSort
                        value={this.state.listaItemCategoria}
                        onSelectionChange={pEvento => {
                            this.setState({
                                itemCategoria: pEvento.value,
                                tela: "2",
                            })
                        }}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        <Column header="Foto" body={pItem =>
                            <div style={{ width: 100, height: 100, backgroundColor: "lightgray", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {pItem.imagem ?
                                    <img
                                        src={this.state.urlBase + pItem.imagem}
                                        style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                    :
                                    <p>sem imagem</p>
                                }
                            </div>
                        }></Column>
                        <Column field="codigo" header="Código" sortable></Column>
                        <Column field="nome" header="Nome" sortable></Column>
                        <Column field="descricao" header="Descrição" sortable></Column>
                        <Column field="situacao" header="Situação" sortable></Column>
                    </DataTable>
                </main>
            }
        </div>
    }
}