export default class Constante {
    // /* DESENVOLVIMENTO */
    // url_base = 'http://192.168.1.20:8080/v1/api/';
    url_base = 'https://api.lirida.com.br/v1/api/';
    linkProjeto = 'http://192.168.1.14:3000/checkout?id='

    /* PRODUÇÃO */
    // url_base = 'https://pallua.com.br/lirida_novo/api/';
    // linkProjeto = 'https://teste.pallua.com.br/checkout?id='

    token = 'a0dd006c-9cd7-47e7-8791-4a696520e5e3';

    urlImagemPlaceholder = 'https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png'
    imagemUsuarioPlaceholder = 'https://media.istockphoto.com/vectors/default-avatar-profile-icon-grey-photo-placeholder-hand-drawn-modern-vector-id1273297967?k=20&m=1273297967&s=170667a&w=0&h=Iqo2x8teHMYPDg1OWFuPJOr6xBAFRLtJyIdTBPcLW7k=';

    corPrincipal = '#f08d44';

    //USUARIO
    seqUsuario = '15b70ca7-dff6-4455-a8db-a443f425cac5';

    //PERFIL
    seqPerfilLojista = "aff44efa-7f2f-4142-b419-7f89d249581b";
    seqPerfilVendedor = "a63604e8-d97b-4274-b135-baae1cb9fbd0";
    seqPerfilCliente = "23ae6d77-f1cb-4169-92c0-b8fb1fd484a7";
    seqPerfilAdministrador = "448b1404-532a-4fa3-b993-7398c349ed4b";

    //TIPO-DE-DOCUMENTO
    seqTipoDocumentoPedido = "f618b294-7a95-47f2-9a0d-2817e61fc4a4";

    //DOCUMENTO-PROCESSO
    seqTipoDocumentoProcessoAguardandoPagamento = "edb37640-cff0-4b31-89a3-ca7348964ad2";
    seqTipoDocumentoProcessoPago = "c798a965-79f5-46eb-869b-f3d33bc66082";
    seqTipoDocumentoProcessoEmSeparacao = "799c2e63-c24b-4635-bf38-ff66394070a4";
    seqTipoDocumentoProcessoEmTransito = "b81cc8a5-3fb2-428c-a278-e08359371e8c";
    seqTipoDocumentoProcessoFinalizado = "a48a69da-c6a1-4e05-8b64-4402a349cafd";

    //RELACIONAMENTO
    seqTipoRelacionamentoVendedorResponsavel = "f5e1da9b-9cec-4f7b-bf3c-85a2874ef4f3";
    seqTipoRelacionamentoVendedorParceiro = "b5cbc42e-2950-4dc3-9f11-b0a026d482ef";
    seqTipoRelacionamentoLojistaParceiro = "bd143b27-a5ef-4ea5-9712-aad4d479b995";
    seqTipoRelacionamentoLoja = "fd148422-2b1b-48fa-9d4b-29ea58bdc637";



    //TABELA-DE-PRECO
    seqTabelaPrecoPradrao = "6629d09a-6c26-4618-8ae5-8c56b2d2d464";

    //EMAIL
    seqEmailInformaçõesAcesso = "75394c4d-d8ca-4d5b-8adb-9faeb57792d0"

    //FORMA-DE-PAGAMENTO
    seqFormaPagamentoPix = "1e938206-9815-497f-a00a-c7feec130285";
    seqFormaPagamentoBoleto = "1c7f0398-db99-4a91-929b-8463d2e53f60";
    seqFormaPagamentoCartaoCredito = "f7d5fdcd-f40e-493c-864b-8ff847a2c21d";

    //CONTA-BANCARIA
    seqContaBancariaPrincipal = "17e6fbe8-5055-4f97-9b62-d0b1646116f4";

    //PLANO-DE-CONTAS-FINANCERO
    seqPlanoContasPagamentoLojista = "03927dc3-bf9e-4b3f-b23c-8ac0cbecbcb2";

    //CONDIÇÃO-DE-PAGAMENTO
    seqCondicaoPagamentoPadrao = "b9e0e8ae-7453-4fd2-b1f3-6d8cdf492dff";
}