import { Component } from "react";
import imagemBanner from '../../assets/imagens/slide.png';
import imagemFlutuante1 from '../../assets/imagens/imagemFlutuante1.png';
import imagemFlutuante2 from '../../assets/imagens/imagemFlutuante2.png';

import { Accordion, AccordionTab } from 'primereact/accordion';
import Constante from "../../util/Constante";

export default class Hotsite extends Component {
  constante = new Constante();

  componentDidMount() {
    this.iniciar();
  }

  iniciar() {
    if (window.location.href.includes("#")) {
      const rotaHashtag = window.location.href.split("#")[1];
      window.location.href = "#" + rotaHashtag;
    }
  }

  render() {
    return <>
      <main className="h-main-container">
        <img className="h-slide-img" src={imagemBanner} alt="imagem principal" />

        <div className="h-sobre">
          <section className="h-sobre-container" id="sobre">
            <div>
              <i className="pi pi-building" style={{ fontSize: 30 }}></i>
              <h2>Quem somos</h2>
              <p>
                A AW Obras é a solução que foi feita para te fazer vender mais! Viemos conectar lojistas a especialistas de obra como arquitetos, engenheiros e mestres de obra. Assim fazendo o lojista vender mais e facilitar a vida dos profissionais de obra.
              </p>
            </div>
            <div>
              <i className="pi pi-cog" style={{ fontSize: 30 }}></i>
              <h2>Como funciona</h2>
              <p>
                O Lojista se cadastra e tem o acesso para cadastrar todos os produtos que quiser da sua loja, a partir daí o profissional que deseja se credenciar a essa loja terá a autorização para vender os produtos dessa loja e ser comissionado por isso. Simples, feito para você vender mais.
              </p>
            </div>
            <div>
              <i className="pi pi-users" style={{ fontSize: 30 }}></i>
              <h2>Para quem</h2>
              <p>
                Feito pra você, lojista que quer ter a maior rede de vendedores a sua disposição! Vendedores comissionados que vão alavancar suas vendas, além de facilitar a vida dos profissionais de obra que poderão dar orçamentos imediatos ao cliente e ganhar uma comissão pela venda dos materiais.
              </p>
            </div>
          </section>
        </div>

        <div className="h-func">
          <section className="h-func-container" id="funcionalidades">
            <div>
              <p>
                Alavanque suas vendas de forma exponencial e zere o seu estoque
              </p>
              <img src={imagemFlutuante1} alt="imagem flutuante" />
            </div>

            <div>
              <p>
                Dê orçamentos e cotação na hora que precisar, na hora quiser
              </p>
            </div>

            <div>
              <img src={imagemFlutuante2} alt="imagem flutuante" />
              <p>
                Faça a gestão de suas vendas e gestão do seu estoque pela tela do computador
              </p>
            </div>
          </section>
        </div>

        <div className="h-depo">
          <section className="h-depo-container" id="depoimentos">
            <div>
              <img src={this.constante.imagemUsuarioPlaceholder} alt="foto pessoa" />
              <div>
                <h2>
                  Antônio (Lojista)
                </h2>
                <p>
                  “ O AW Obras foi a solução que eu precisava para vender mais. E ainda consigo fazer a gestão da minha loja pelo aw obras, super fácil.”
                </p>
              </div>
            </div>

            <div>
              <img src={this.constante.imagemUsuarioPlaceholder} alt="foto pessoa" />
              <div>
                <h2>
                  Marcelo (Lojista)
                </h2>
                <p>
                  "O mais impressionante do Aw Obras é que eu vendo mais e não tenho custo adicional, porque todos que fazem os papéis de vendedores recebem por comissão."

                </p>
              </div>
            </div>

            <div>
              <img src={this.constante.imagemUsuarioPlaceholder} alt="foto pessoa" />
              <div>
                <h2>
                  Paulo (Arquiteto)
                </h2>
                <p>
                  “ Depois que comecei a usar o AW Obras consegui fechar mais contratos, porque assim que dou a lista de materiais para o cliente ele não precisa mais correr atrás de lojas por que já entrego tudo e ainda ganho uma comissão por isso.”
                </p>
              </div>
            </div>
          </section>
        </div>

        <section className="h-faq-container" id="perguntas-frequentes">
          <h2>Perguntas frequentes</h2>
          <Accordion multiple>
            <AccordionTab header="Preciso pagar alguma mensalidade para usar a plataforma ?">
              <p> Não, para utilizar nossa plataforma e ter acesso aos recursos é totalmente livre de mensalidade.</p>
            </AccordionTab>

            <AccordionTab header="Depois que criei a conta, posso editar minhas informações ?">
              <p>Sim, suas informações ficam disponíveis para edição quando você quiser.</p>
            </AccordionTab>

            <AccordionTab header="Como faço para me cadastrar na plataforma ?">
              <p> Entre em contato conosco no email atendimento@awobras.com.br</p>
            </AccordionTab>

            <AccordionTab header="Eu posso cancelar ou excluir minha conta ?">
              <p> Sim, você tem total autonomia e controle sobre sua conta A qualquer momento você exclui sua sem custo nenhum.</p>
            </AccordionTab>

            <AccordionTab header="Preciso passar por alguma análise ?">
              <p>Sim, buscamos levar o melhor atendimento para os clientes, assim para manter a segurança e qualidade dos serviços é preciso que o profissional passe por uma análise.</p>
            </AccordionTab>
          </Accordion>
        </section>


      </main>
    </>
  }
}