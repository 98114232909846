import { Component } from "react";
import Header from "../sistema/components/header";
import ItemService from 'lirida-back-service/Servicos/Item/ItemService';
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Item from "lirida-back-service/Servicos/Item/Item";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemDetalhe from "./itemDetalhe";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class ItemPg extends Component {
    constante = new Constante();
    util = new Util();
    itemService = new ItemService(this.constante.token, this.constante.url_base);

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        item: new Item(),
        listaItem: [],
        conteudo: "",
        tela: "1",
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        this.listar(pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase
        })
    }

    async listar(pPessoa) {
        if (!pPessoa) {
            pPessoa = this.state.pessoaUsuario
        }
        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "item.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item.seq_pessoa";
        pesquisa.conteudo = pPessoa._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item.nome";
        pesquisa.conteudo = this.state.conteudo;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        
        
        console.log(listaPesquisa);

        const retornoListaItem = await this.itemService.listarComFiltro(listaPesquisa);
        const listaItem = retornoListaItem.objeto;
        console.log(listaItem);

        this.setState({ listaItem: listaItem });
    }

    render() {
        return <div>
            <Header />

            {this.state.tela === "2" ?
                <ItemDetalhe
                    listar={() => this.listar()}
                    setState={pState => this.setState(pState)}
                    item={this.state.item} />
                :
                <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                        <h1 style={{ fontSize: 22.5 }}>Cadastro de item</h1>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                            <InputText
                                placeholder="Pesquisar"
                                onChange={pEvento => this.setState({ conteudo: pEvento.target.value })}
                                value={this.state.conteudo} />
                            <Button
                                label="Buscar"
                                onClick={() => this.listar()}
                                className="p-button-raised" />
                            <Button
                                label="Novo"
                                onClick={() => this.setState({
                                    tela: "2",
                                })}
                                className="p-button-raised" />
                        </div>
                    </div>

                    <DataTable
                        style={{ marginTop: 20 }}
                        selectionMode="single"
                        showGridlines
                        removableSort
                        value={this.state.listaItem}
                        onSelectionChange={pEvento => {
                            this.setState({
                                item: pEvento.value,
                                tela: "2",
                            })
                        }}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        <Column header="Foto" body={pItem =>
                            <div style={{ width: 100, height: 100, backgroundColor: "lightgray", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {pItem.imagem ?
                                    <img
                                        src={this.state.urlBase + pItem.imagem}
                                        style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                    :
                                    <p>sem imagem</p>
                                }
                            </div>
                        }></Column>
                        <Column field="codigo" header="Código" sortable></Column>
                        <Column field="nome" header="Nome" sortable></Column>
                        <Column field="descricao" header="Descrição" sortable></Column>
                        <Column field="situacao" header="Situação" sortable></Column>
                    </DataTable>
                </main>
            }
        </div>
    }
}