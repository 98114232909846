import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from 'primereact/messages';
import { Component, createRef } from "react";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import logo from '../../assets/imagens/logo.png';

export default class LoginPg extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    mensagem = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        carregando: false,
        inputError: {
            email: false,
            senha: false,
        }
    }

    async validarAcesso(pEvento) {
        pEvento.preventDefault();

        let pessoaUsuario = this.state.pessoaUsuario;
        let pessoa = null;
        const inputError = this.state.inputError;
        const listaPesquisa = [];
        let inputOK = true;

        pessoaUsuario.emailPlataformaLirida = pEvento.target[0].value;
        pessoaUsuario.senha = pEvento.target[1].value;

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!pessoaUsuario.senha) {
            inputError.senha = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        });

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ carregando: true });

        this.mensagem.current.clear();

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.email_plataforma_lirida",
                pessoaUsuario.emailPlataformaLirida,
                "AND",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "AND ( pessoa.seq_pessoa_perfil = '" + this.constante.seqPerfilAdministrador + "'",
                this.constante.seqPerfilAdministrador,
                "AND",
                "MANUAL"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "OR pessoa.seq_pessoa_perfil = '" + this.constante.seqPerfilLojista + "')",
                this.constante.seqPerfilLojista,
                "OR",
                "MANUAL"
            )
        );

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);

        retornoListaPessoa?.objeto?.forEach(pPessoa => {
            if (pPessoa.emailPlataformaLirida === pessoaUsuario.emailPlataformaLirida) {
                pessoa = pPessoa;
                console.log(pessoa);
            }
        });

        if (!pessoa) {
            this.mensagem.current.show([
                { severity: 'error', summary: 'Usuário não encontrado', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        if (pessoa.senha !== pessoaUsuario.senha) {
            this.mensagem.current.show([
                { severity: 'error', summary: 'Senha inválida', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        pessoaUsuario = pessoa;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        if (pessoaUsuario.seqPessoaPerfil === this.constante.seqPerfilAdministrador) {
            window.location.href = "/sistema/administrador/pedidos";
        }

        if (pessoaUsuario.seqPessoaPerfil === this.constante.seqPerfilLojista) {
            window.location.href = "/sistema/lojista/dashboard";
        }
    }

    render() {
        return <main style={{ width: "90%", padding: '110px 0 50px 0' }}>

            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} style={{ width: 400, height: 400, borderRadius: 200, objectFit: "contain" }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
                    <Messages ref={this.mensagem} />
                    <h1>Login</h1>
                    <form
                        style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}
                        onSubmit={pEvento => this.validarAcesso(pEvento)}>
                        <div>
                            <label>Email</label><br />
                            <InputText
                                type="text"
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div>
                            <label>Senha</label><br />
                            <InputText
                                type="password"
                                style={{ width: '100%', borderColor: this.state.inputError.senha && 'red' }} />
                            {this.state.inputError.senha && <small style={{ color: 'red' }}>Senha inválida</small>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'Center', justifyContent: "space-between" }}>
                            {/* <Button
                                type="button"
                                label="Cadastre-se"
                                className="p-button-link"
                                onClick={() => window.location.href = "/Cadastro"} /> */}

                            <Button
                                type="button"
                                label="Recuperar-senha"
                                className="p-button-link"
                                onClick={() => window.location.href = "/recuperar_senha"} />

                            <Button
                                loading={this.state.carregando}
                                type="submit"
                                label="Entrar" />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    }
}