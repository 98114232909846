import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from 'primereact/messages';
import { Component, createRef } from "react";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import logo from '../../assets/imagens/logo.png';

export default class RecuperarSenhaPg extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    mensagem = createRef();

    state = {
        pessoaUsuario: new Pessoa(),
        carregando: false,
        inputError: {
            email: false,
        }
    }

    async recuperarSenha(pEvento) {
        pEvento.preventDefault();

        let pessoaUsuario = this.state.pessoaUsuario;
        let pessoa = null;
        const inputError = this.state.inputError;
        const listaPesquisa = [];
        let inputOK = true;

        pessoaUsuario.emailPlataformaLirida = pEvento.target[0].value;

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!pessoaUsuario.emailPlataformaLirida) {
            inputError.email = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        });

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ carregando: true });

        this.mensagem.current.clear();

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "pessoa.email_plataforma_lirida",
                pessoaUsuario.emailPlataformaLirida,
                "AND",
                "TEXTO"
            )
        );

        // listaPesquisa.push(
        //     this.util.retornaPesquisa(
        //         "AND ( pessoa.seq_pessoa_perfil = '" + this.constante.seqPessoaPerfilAdministrador + "'",
        //         this.constante.seqPessoaPerfilAdministrador,
        //         "AND",
        //         "MANUAL"
        //     )
        // );

        listaPesquisa.push(
            this.util.retornaPesquisa(
                "OR pessoa.seq_pessoa_perfil = '" + this.constante.seqPerfilLojista + "')",
                this.constante.seqPessoaPerfilLogista,
                "OR",
                "MANUAL"
            )
        );

        const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);

        retornoListaPessoa?.objeto?.forEach(pPessoa => {
            if (pPessoa.emailPlataformaLirida === pessoaUsuario.emailPlataformaLirida) {
                pessoa = pPessoa;
                console.log(pessoa);
            }
        });

        if (!pessoa) {
            this.mensagem.current.show([
                { severity: 'error', summary: 'Usuário não encontrado', sticky: true },
            ]);
            this.setState({ carregando: false });
            return;
        }

        const apiEmail = {
            seqEmail: this.constante.seqEmailInformaçõesAcesso,
            email: pessoa.emailPlataformaLirida,
            parametro: {
                nome: pessoa.nome,
                email: pessoa.emailPlataformaLirida,
                senha: pessoa.senha
            }
        }

        // const apiWhatsApp = {
        //     seq_zap_modelo: this.constante.seqWhatsAppAcesso,
        //     telefone: pessoa.telefonePlataformaLirida,
        //     parametro: {
        //         nome: pessoa.nome,
        //         usuario: pessoa.email,
        //         senha: pessoa.senha
        //     }
        // }

        this.util.enviarEmail(apiEmail);
        // this.util.enviarWhatsApp(apiWhatsApp);

        this.mensagem.current.show([
            { severity: 'success', summary: 'Enviamos as credênciais de acesso para o seu email!', sticky: true },
        ]);

        this.setState({ carregando: false });
    }

    render() {
        return <main style={{ width: "95%", padding: '110px 0 50px 0' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} style={{ width: 400, height: 400, borderRadius: 200, objectFit: "contain" }} />
                </div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
                    <Messages ref={this.mensagem} />
                    <h1>Recuperar-senha</h1>
                    <form
                        style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 20 }}
                        onSubmit={pEvento => this.recuperarSenha(pEvento)}>
                        <div>
                            <label>Email</label><br />
                            <InputText
                                type="text"
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }} />
                            <small style={{ color: this.state.inputError.email ? 'red' : 'transparent' }}>Email inválido</small>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20 }}>
                            <Button
                                style={{ padding: 0 }}
                                type="button"
                                label="Voltar à tela de Login"
                                className="p-button-link"
                                onClick={() => window.location.href = "/login"} />
                            <Button
                                style={{ marginTop: 20 }}
                                loading={this.state.carregando}
                                type="submit"
                                label="Enviar" />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    }
}