import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { locale, addLocale } from 'primereact/api';

import "./index.css";

// Primereact
import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


//PAGES

//COMPONENTS
import HeaderHotsite from './pages/hotsite/components/HeaderHotsite';
import FooterHotsite from './pages/hotsite/components/FooterHotsite';

//HOTSITE
import HotSite from './pages/hotsite';

//AUTENTICAÇÃO
import LoginPg from './pages/autenticação/login';
import RecuperarSenhaPg from './pages/autenticação/recuperarSenha';
import CadastroPg from './pages/autenticação/cadastro';

//ADMINISTRADOR
import DashboardAdministrador from './pages/sistema/administrador/dashboard';
import PedidoAdm from './pages/sistema/administrador/pedidosAdm';
import PedidoDetalheAdm from './pages/sistema/administrador/pedidoDetalheAdm';
import LojistaPg from './pages/sistema/administrador/lojista';
import LojistaDetalhe from './pages/sistema/administrador/lojistaDetalhe';
import PerfilAdministrador from './pages/sistema/administrador/perfil';
import FinanceiroContasPagarPg from './pages/sistema/administrador/financeiroPagar';
import FinanceiroContasReceberPg from './pages/sistema/administrador/financeiroReceber';


//SISTEMA
import Perfil from './pages/sistema/perfil';
import TabelaPrecoPg from './pages/sistema/tabelaPreco';
import ItemPg from './pages/sistema/itemPg';
import ItemDetalhe from './pages/sistema/itemDetalhe';
import ItemCategoriaPg from './pages/sistema/itemcategoria';
import VendedorPg from './pages/sistema/vendedorPg';
import VendedorDetalhe from './pages/sistema/vendedorDetalhe';
import TermoUso from './pages/hotsite/termoDeUso';
import PoliticaPrivacidade from './pages/hotsite/politicaPrivacidade';
import Dashboard from './pages/sistema/dashboard';
import PedidosPg from './pages/sistema/pedidos';
import PedidoDetalhe from './pages/sistema/pedidoDetalhe';
import FinanceiroPg from './pages/sistema/financeiro';

/* Alterando lingua de en pra pt calendario */
addLocale('br', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
  monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
  today: 'Hoje',
  clear: 'Limpar'
});

locale('br');


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<BrowserRouter>
  <Routes>
    <Route path="/*" element={<>
      <HeaderHotsite />
      <Routes>
        <Route path="/" element={<HotSite />} />
        <Route path="/termos_de_uso" element={<TermoUso />} />
        <Route path="/politica_de_privacidade" element={<PoliticaPrivacidade />} />
        <Route path="/login" element={<LoginPg />} />
        <Route path="/recuperar_senha" element={<RecuperarSenhaPg />} />
        <Route path="/cadastro" element={<CadastroPg />} />
      </Routes>
      <FooterHotsite />
    </>} />

    <Route path="/sistema/lojista/*" element={
      <Routes>
        <Route path="/pedidos" element={<PedidosPg />} />
        <Route path="/pedido_detalhe" element={<PedidoDetalhe />} />
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/tabela_de_preco" element={<TabelaPrecoPg />} />
        <Route path="/cadastro" element={<ItemPg />} />
        <Route path="/item_detalhe" element={<ItemDetalhe />} />
        <Route path="/categoria" element={<ItemCategoriaPg />} />
        <Route path="/categoria_detalhe" element={<ItemCategoriaPg />} />
        <Route path="/vendedor" element={<VendedorPg />} />
        <Route path="/vendedor_detalhe" element={<VendedorDetalhe />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/financeiro" element={<FinanceiroPg />} />
      </Routes>
    } />


    <Route path="/sistema/administrador/*" element={
      <Routes>
        <Route path="/pedidos" element={<PedidoAdm />} />
        <Route path="/pedido_detalhe_adm" element={<PedidoDetalheAdm />} />
        <Route path="/lojista" element={<LojistaPg />} />
        <Route path="/lojista_detalhe" element={<LojistaDetalhe />} />
        <Route path="/dashboard" element={<DashboardAdministrador />} />
        <Route path="/perfil" element={<PerfilAdministrador />} />
        <Route path="/contas_a_pagar" element={<FinanceiroContasPagarPg />} />
        <Route path="/contas_a_receber" element={<FinanceiroContasReceberPg />} />
      </Routes>
    } />


  </Routes>
</BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
