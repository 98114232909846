

import { Component, createRef } from "react";
import Constante from "./../../util/Constante";
import Util from "./../../util/Util";
import TabelaPrecoService from 'lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService';
import Header from "../sistema/components/header";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class TabelaPrecoPg extends Component {
    constante = new Constante();
    util = new Util();
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base);
    toast = createRef();

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        listaTabelaPrecoItem: [],
        carregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();

        this.listar(pessoaUsuario);

        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario
        });
    }

    salvar() {
        const listaTabelaPrecoItem = this.state.listaTabelaPrecoItem;

        this.setState({ carregando: true });

        listaTabelaPrecoItem.forEach(async pTabelaPrecoItem => {
            await this.tabelaPrecoService.salvarItem(pTabelaPrecoItem);
        });

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.listar(this.state.pessoaUsuario);

        this.setState({ carregando: false });
    }

    async listar(pPessoa) {
        console.log(pPessoa);
        if (!pPessoa) {
            pPessoa = this.state.pessoaUsuario
        }

        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "item.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "item.seq_pessoa";
        pesquisa.conteudo = pPessoa._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "tabela_preco.seq_tabela_preco";
        pesquisa.conteudo = pPessoa.seqTabelaPreco;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";

        listaPesquisa.push(pesquisa);


        // pesquisa = new Pesquisa();
        // pesquisa.campo = "item.nome";
        // pesquisa.conteudo = this.state.conteudo;
        // pesquisa.operacao = "AND";
        // pesquisa.tipo = "TEXTO";

        // listaPesquisa.push(pesquisa);

        console.log(listaPesquisa);

        const retornoListaTabelaPrecoItem = await this.tabelaPrecoService.listarComFiltro(listaPesquisa);
        const listaTabelaPrecoItem = retornoListaTabelaPrecoItem.objeto;
        console.log(listaTabelaPrecoItem);

        this.setState({ listaTabelaPrecoItem: listaTabelaPrecoItem });
    }

    render() {
        return <div>
            <Header />

            <Toast ref={this.toast} />

            <div style={{ width: '98%', margin: '20px auto 0 auto', border: "1px solid lightgray", borderRadius: 5, padding: "20px 10px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 style={{ fontSize: 22 }}>Tabela de preço</h1>

                <Button
                    loading={this.state.carregando}
                    label="Salvar"
                    onClick={() => this.salvar()} />
            </div>

            <DataTable
                style={{ marginTop: 20 }}
                showGridlines
                removableSort
                value={this.state.listaTabelaPrecoItem}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                <Column header="Foto" body={pItem =>
                    <div style={{ width: 100, height: 100, backgroundColor: "lightgray", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {pItem._itemImagem ?
                            <img
                                src={this.state.urlBase + pItem._itemImagem}
                                style={{ width: 100, height: 100, objectFit: 'contain' }} />
                            :
                            <p>sem imagem</p>
                        }
                    </div>
                }></Column>
                <Column field="_itemCodigo" header="Código" sortable></Column>
                <Column field="_itemNome" header="Nome" sortable></Column>
                <Column field="situacao" header="Situação" sortable></Column>
                <Column header="Preço (R$)" body={pItem =>
                    <InputText
                        type="number"
                        onChange={pEvento => pItem.preco = pEvento.target.value}
                        defaultValue={pItem.preco} />
                } sortable></Column>
            </DataTable>
        </div>
    }
}