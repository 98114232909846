import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Component, createRef } from "react";
import Constante from "../../util/Constante";
import Util from "../../util/Util";
import VendedorDetalhe from "./vendedorDetalhe";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import Header from "./components/header";

export default class Vendedor extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    message = createRef();
    toast = createRef();

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        pessoaRelacionamento: new PessoaRelacionamento(),
        listaPessoaRelacionamento: [],
        conteudo: "",
        tela: "1",
        novoVisivel: false,
        inputError: {
            email: false,
        },
        relacionamentoCarregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        this.listar(pessoaUsuario);

        console.log(pessoaUsuario);

        this.setState({
            pessoaUsuario: pessoaUsuario,
        });
    }

    async listar(pPessoa) {
        const retornoListaPessoaRelacionamento = await this.pessoaService.listarRelacionamento(pPessoa);
        const listaPessoaRelacionamento = retornoListaPessoaRelacionamento.objeto;

        console.log(listaPessoaRelacionamento);

        this.setState({ listaPessoaRelacionamento: listaPessoaRelacionamento });
    }

    async novo(pEvento) {
        pEvento.preventDefault();
        console.log(pEvento.target[0].value);

        this.setState({ relacionamentoCarregando: true });

        const inputError = this.state.inputError;

        this.message.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        this.setState({ inputError: inputError });

        if (!pEvento.target[0].value) {
            inputError.email = true;
        }

        const retornoValidacao = this.util.validarEmail(pEvento.target[0].value);

        if (!retornoValidacao) {
            inputError.email = true;
        }

        let inputOK = true;

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        })

        if (!inputOK) {
            this.setState({
                inputError: inputError,
                relacionamentoCarregando: false,
            });
            return;
        }

        const listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_pessoa_perfil";
        pesquisa.conteudo = this.constante.seqPerfilVendedor;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.email_plataforma_lirida";
        pesquisa.conteudo = pEvento.target[0].value;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        const retornoListaPessoaRelacionamento = await this.pessoaService.listarComFiltro(listaPesquisa);
        const pessoa = retornoListaPessoaRelacionamento.objeto[0];


        if (!pessoa?._seqPessoa) {
            this.message.current.show([
                { severity: 'error', summary: 'Usuário não encontrado!' },
            ]);

            this.setState({
                relacionamentoCarregando: false
            });
            return;
        }

        const pessoaRelacionamento = new PessoaRelacionamento();
        pessoaRelacionamento.seqPessoa = this.state.pessoaUsuario._seqPessoa;
        pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoVendedorParceiro;
        pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
        pessoaRelacionamento.seqPessoaRelacionada = pessoa._seqPessoa;

        await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);

        this.setState({
            relacionamentoCarregando: false,
            novoVisivel: false,
        });

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.listar(this.state.pessoaUsuario);
    }

    render() {
        return <div>
            <Header />

            <Toast ref={this.toast} />

            <Dialog
                style={{ maxWidth: 400, width: "100%" }}
                visible={this.state.novoVisivel}
                onHide={() => this.setState({ novoVisivel: false })}
                header="Novo vendedor">
                <Messages ref={this.message} />

                <form
                    onSubmit={(pEvento) => this.novo(pEvento)}>
                    <div>
                        <label>Email do vendedor</label>
                        <InputText
                            style={{ width: "100%", borderColor: this.state.inputError.email && 'var(--red-400)' }} />
                        {this.state.inputError.email && <small style={{ color: 'var(--red-400)' }}>Email inválido</small>}
                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                            <Button
                                loading={this.state.relacionamentoCarregando}
                                label="Salvar"
                                type="submit"
                                style={{ marginTop: 20 }} />
                        </div>
                    </div>
                </form>
            </Dialog>

            {this.state.tela === "2" ?
                <VendedorDetalhe
                    setState={pState => this.setState(pState)}
                    pessoaRelacionamento={this.state.pessoaRelacionamento} />
                :
                <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                        <h1 style={{ fontSize: 22.5 }}>Vendedores</h1>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                            <InputText
                                placeholder="Pesquisar"
                                onChange={pEvento => this.setState({ conteudo: pEvento.target.value })}
                                value={this.state.conteudo} />
                            <Button
                                label="Buscar"
                                onClick={() => this.listar(this.state.pessoaUsuario)}
                                className="p-button-raised" />
                            <Button
                                label="Novo"
                                onClick={() => this.setState({ novoVisivel: true })}
                                className="p-button-raised" />
                        </div>
                    </div>

                    <DataTable
                        style={{ marginTop: 20 }}
                        selectionMode="single"
                        showGridlines
                        removableSort
                        value={this.state.listaPessoaRelacionamento}
                        onSelectionChange={pEvento => {
                            // console.log(pEvento.value)
                            this.setState({
                                pessoaRelacionamento: pEvento.value,
                                tela: "2",
                            })
                        }}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
                        <Column field="_pessoaRelacionadaNome" header="Nome" sortable></Column>
                        <Column field="_tipoRelacionamentoNome" header="Função" sortable></Column>
                    </DataTable>
                </main>
            }
        </div>
    }
}